import { useQuery } from "react-query";

import { _news } from "api/news/news";

export const useNews = () => {
    const { data, isLoading } = useQuery(
        [`_news`],
        () => _news.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};