import { useQuery } from "react-query";

import { _videos } from "api/videos/videos";

export const useVideos = () => {
    const { data, isLoading } = useQuery(
        [`_videos`],
        () => _videos.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};