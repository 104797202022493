import { useQuery } from "react-query";
import { _product } from "api/home/Product";

export const useProduct = () => {
    const { data, isLoading } = useQuery(
        ["product"],
        () => _product.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};
