// user/dealership/index
import { _axios } from "interceptor/http-config";

export const _categories = {
    index: async () => {
        return _axios
            .get(
                `user/category/index`
                , {
                    headers: {
                        lang: ["en", "ar"].includes(
                            localStorage.getItem("i18nextLng")
                        )
                            ? localStorage.getItem("i18nextLng")
                            : "en"
                    }
                })
            .then((res) => res.data);
    },
};