import { useEffect, useState } from "react"
import Title from "components/Title"
import { Button, Col, Container, Input, Row, Form, Label, FormGroup } from "reactstrap"

// icons 
import facebook from "../asset/images/icon/facebook.svg"
import gPlus from "../asset/images/icon/Gplus.svg"
import insta from "../asset/images/icon/inst.svg"
import linkedin from "../asset/images/icon/linkedin.svg"
import twitter from "../asset/images/icon/twitter.svg"
import logo from "../asset/images/logo_blue.png"
import { _contactUs } from "api/contactUs/contactUs"
import { useTranslation } from "react-i18next"

const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [mydata, setmyData] = useState({
        FirstName: "",
        LastName: "",
        Message: "",
        Email: "",
        Phone: "",
    });
    const [agree, setAgree] = useState()

    const handleContactFormDataChange = (event) => {
        event.preventDefault();
        const { value, name } = event.target;

        setmyData((old) => ({
            ...old,
            [name]: value,
        }));
    }
    const { t } = useTranslation("index")
    const handleSubmit = (event) => {
        event.preventDefault();
        const formData = new FormData()
        formData.append('first_name', mydata.FirstName)
        formData.append('last_name', mydata.LastName)
        formData.append('email', mydata.Email)
        formData.append('phone', mydata.Phone)
        formData.append('message', mydata.Message)
        _contactUs.index(formData)
    }

    return <div className="bg-blue-tick">
        <Title name={t('DOCUMENTS')} />
        <Container className="mt-5">
            <Row className="mt-md-5 mt-1" >
                <Col md="6" className="text-secondary mt-1 mt-md-5">
                    <h2 className="mb-md-5 mb-4">{t('Address')}</h2>
                    <div className="fs-4 mt-1 mt-md-4">{t('Tel')} : +9765 212 886 54 50/51/52/53</div>
                    <div className="fs-4 mt-1 mt-md-4">{t('Fax')} : +90 212 886 54 50/51/52/53</div>
                    <div className="fs-4 mt-1 mt-md-4">{t('E-Mail')} : Info@Rainbow.com</div>
                    {/* <div className="d-flex mt-4">
                        <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-secondary">
                            <a href="http://" target="_blank" rel="noopener noreferrer">
                                <img src={facebook} alt="facebook icon" />
                            </a>
                        </Button>
                        <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-secondary">
                            <a href="http://" target="_blank" rel="noopener noreferrer">
                                <img src={gPlus} alt="facebook icon" />
                            </a>
                        </Button>
                        <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-secondary">
                            <a href="http://" target="_blank" rel="noopener noreferrer">
                                <img src={insta} alt="insta icon" />
                            </a>
                        </Button>
                        <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-secondary">
                            <a href="http://" target="_blank" rel="noopener noreferrer">
                                <img src={linkedin} alt="linkedin icon" />
                            </a>
                        </Button>
                        <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-secondary">
                            <a href="http://" target="_blank" rel="noopener noreferrer">
                                <img src={twitter} alt="twitter icon" />
                            </a>
                        </Button>
                    </div> */}
                </Col>
                <Col md="6" className="d-flex align-items-center justify-content-center">
                    <img className="contactLogo  py-md-0 py-5 " src={logo} alt="rainbow plast logo" />
                </Col>
            </Row>
            <Form className="mt-md-5 mt-2 py-md-5 py-1" onSubmit={handleSubmit}>
                <h2 className="text-center text-white formTitle">{t('Order From')}</h2>
                <Row className="py-5 ">
                    <Col md="6">
                        <Input
                            id="FirstName"
                            name="FirstName"
                            placeholder={t("First Name")}
                            type="text"
                            className="border-0 px-4 py-2 fs-5 mb-4 formInput"
                            onChange={handleContactFormDataChange}
                        />
                    </Col>
                    <Col md="6">
                        <Input
                            id="lastName"
                            name="LastName"
                            placeholder={t("Last Name")}
                            type="text"
                            className="border-0 px-4 py-2 fs-5 mb-4 formInput" onChange={handleContactFormDataChange}
                        />
                    </Col>
                    <Col md="6">
                        <Input
                            id="Email"
                            name="Email"
                            placeholder={t("E-Mail")}
                            type="email"
                            className="border-0 px-4 py-2 fs-5 mb-4 formInput" onChange={handleContactFormDataChange}
                        />
                    </Col>
                    <Col md="6">
                        <Input
                            id="Phone"
                            name="Phone"
                            placeholder={t("Phone")}
                            type="tel"
                            className="border-0 px-4 py-2 fs-5 mb-4 formInput" onChange={handleContactFormDataChange}
                        />
                    </Col>
                    <Col md="12">
                        <Input
                            id="Message"
                            name="Message"
                            placeholder={t("Message")}
                            type="textarea"
                            className="border-0 px-4 py-2 fs-5 mb-4 formInput"
                            onChange={handleContactFormDataChange}
                        />
                        <div className={`mx-md-5 my-md-3 d-flex justify-content-start align-items-center dir`}>
                            <Input
                                type="checkbox"
                                className="p-2 me-3 me-md-5 text-white bg-transparent "
                                name="Check"
                                id="Check"
                                onChange={e => setAgree(e.target.checked)} />
                            <Label htmlFor="Check" className="text-white mx-2 fs-6 fs-md-4 mb-0">
                                {t('I agree to receive commercial electronic messages within the scope of the express consent text')}
                            </Label>
                        </div>
                        <div className="w-100 d-flex justify-content-end " >
                            <Button className="border-bill px-5 fs-4 mt-4 py-2 Formbtn" disabled={!agree} type="submit">{t('send')}</Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </Container>
    </div >
}

export default Contact