import React, { useEffect } from "react";
import Main from "../asset/images/Products/main.png"
import { Col, Container, Row, Spinner } from "reactstrap";
import ProductCard from "../components/ProductCard";
import { useAllProduct } from "hooks/Product/useAllProduct"
import { Link } from "react-router-dom";

const Products = () => {
    const { data } = useAllProduct()
    const products = data ? data.products : ''
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<section className="tech_bg">
        <img src={Main} alt="Products" className="w-100 corner-left d-none d-md-block" />
        <Container className="text-center p-md-5 ">
            {data ? <div>
                <Row className="py-5">
                    {products.map((item, index) => {
                        return <Col key={item.id} md='4' className="d-flex flex-column">
                            <Link to={`Previews/${item.id}`}>
                                <ProductCard index={index} img={item.image} code={item.code} name={item.name} size={item.size} />
                            </Link>
                        </Col>
                    })}
                </Row>
            </div> : <Spinner color="primary" className="mx-auto"></Spinner>}
        </Container>
    </section>)
}

export default Products