import React, { useState, useEffect } from "react";
//reactstrap
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarText,
  Button,
  Row,
  Col,
} from "reactstrap";

// translation
import { useTranslation } from "react-i18next";

// logo
import Logo from "../../asset/images/logo.png"

import { Link } from "react-router-dom";
import { settingsStore } from "store/settingsStore";
import { useCategory } from "hooks/categories/useCaregory";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false)
  const toggle = () => setIsOpen(!isOpen)
  const { t, i18n } = useTranslation("navbar")
  const [isHovered, setIsHovered] = useState(false)

  const [direction, setDirection] = settingsStore((state) => [
    state.direction,
    state.setDirection,
  ]);

  const toggleLang = () => {
    setDirection(direction === "ltr" ? "rtl" : "ltr");
    i18n.changeLanguage(direction === "ltr" ? "ar" : "en");
  };

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false)

  const { data } = useCategory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar expand="md" className="bg-primary px-3 py-0 navBar">
        <NavbarBrand href="/"><img src={Logo} alt="rainbow logo" className="logo" /></NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mx-auto w-100 d-flex justify-content-center pe-0 pt-5 pt-md-0" navbar>
            <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('HOME')}
              </NavItem>
            </Link>
            <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/coraporate`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('CORPORATE')}
              </NavItem>
            </Link>
            <Link
              className=" p-md-3 p-lg-5 pt-0 navLink "
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={toggle}
              to={`/Products`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('PRODUCTS')}
              </NavItem>
            </Link>
            {/* <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/Videos`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('VIDEOS')}
              </NavItem>
            </Link> */}
            <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/News`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('NEWS')}
              </NavItem>
            </Link>
            <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/Contact`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('CONTACT')}
              </NavItem>
            </Link>
            <Link onClick={toggle} className=" p-md-3 p-lg-5 pt-0 navLink" to={`/Dealership`}>
              <NavItem className="py-4 border-mobile  p-md-0">
                {t('DEALERSHIP')}
              </NavItem>
            </Link>
          </Nav>
          <NavbarText className="border-left py-4  p-md-0">
            <Button
              className="bg-primary mt-md-0 mt-5 me-5 me-md-0 p-md-3 p-lg-5 pt-0 border-0"
              onClick={toggleLang}
            >
              {t('Ar')}
            </Button>
          </NavbarText>
        </Collapse>
      </Navbar>
      {isHovered && data ?
        <div
          className="w-100  p-md-3 p-lg-5 pt-0 vh-100 bg-primary text-white d-none d-md-block"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Row>
            {data.categories.map((item, index) => {
              return (
                <Col md="4" className={`mt-3 ${index !== 2 ? `border-right` : ''} text-center`} key={item.id}>
                  <h2 className="formTitle ">{item.name}</h2>
                  <div className="list-unstyled">
                    {item.products.slice(0, 6).map((product) => {
                      return (
                        <li key={product.id} className="mt-5 mt-md-3 ">
                          <Link onClick={() => {
                            handleMouseLeave();
                            toggle();
                          }} to={`Products/Previews/${product.id}`}>
                            {product.name}
                          </Link>
                        </li>
                      );
                    })}
                  </div>
                </Col>
              );
            })}
          </Row>
        </div> : ''}
    </div>
  );
}

export default NavBar;
