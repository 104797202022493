// user/dealership/index
import { _axios } from "interceptor/http-config";

export const _dealership = {
    index: async () => {
        return _axios
            .get(
                `user/dealership/index`
            )
            .then((res) => res.data);
    },
};