import { Col, Container, Row, Spinner } from "reactstrap"
import defualt from "../asset/images/defualt.svg"
import { useSimillerProduct } from "hooks/Product/useSimillerProduct"
import ProductCard from "components/ProductCard"
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

const Products = () => {
    const { id } = useParams();
    const { data } = useSimillerProduct(id)
    const product = data ? data.product : null
    const similer_products = data ? data.similer_products : null
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [data])
    const { t } = useTranslation("index");
    return (
        <div className="Products pb-5">
            <Container className=" mt-5 pt-5">
                {data ? <Row className="mt-5">
                    <Col lg='6' md='6' sx={{ padding: '4' }}>
                        <img onError={e => e.target.src = defualt} src={product.image} className="w-100  corner-Square-right" alt="" />
                    </Col>
                    <Col lg='6' md='6'>
                        <Row className="mb-4 mt-1 p-1">
                            {product.gallery.map((image, index) => {
                                return <Col key={index} md='6' xs="4" className="pb-4">
                                    <img onError={e => e.target.src = defualt} className="round w-100 h-100 corner-Square-left" src={image} alt="" />
                                </Col>
                            })}
                        </Row>
                        <h2 className="text-secondary mb-4">{t('Description Product')}</h2>
                        <p className="text-secondary">{product.description}</p>
                    </Col>
                    <h1 className="w-100 text-center my-5  py-5">{t('Same As Products')}</h1>
                    {similer_products.map(item => {
                        return <Col md='4' key={item.id} >
                            <Link to={`/Products/Previews/${item.id}`}>
                                <ProductCard size={item.size} code={item.code} img={item.image} />
                            </Link>
                        </Col>
                    })}
                </Row> : <div className="w-100 d-flex h-100 alighn-items-center justify-content-center">
                    <Spinner size={50} /></div>}
            </Container>
        </div>
    )
}

export default Products