import React from "react";
import { Button } from "reactstrap";

//* images
import facebook from "../../asset/images/icon/facebook.png"
import gPlus from "../../asset/images/icon/Gplus.png"
import insta from "../../asset/images/icon/insta.png"
import linkedin from "../../asset/images/icon/linkedin.png"
import twitter from "../../asset/images/icon/twitter.png"

const Footer = () => {
    return (
        <div className="d-flex py-5 bg-secondary justify-content-center align-items-center text-center text-white flex-column flex-md-row">
            <p className="fs-6 me-0 me-md-5 fs-md-4">2023 RAINBOW PLAST © ALL RIGHTS RESERVED.</p>
            {/* <div className="d-flex">
                <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-white">
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="facebook icon" />
                    </a>
                </Button>
                <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-white">
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                        <img src={gPlus} alt="facebook icon" />
                    </a>
                </Button>
                <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-white">
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                        <img src={insta} alt="insta icon" />
                    </a>
                </Button>
                <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-white">
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                        <img src={linkedin} alt="linkedin icon" />
                    </a>
                </Button>
                <Button style={{ backgroundColor: 'transparent' }} className="mx-1 py-2 border-white">
                    <a href="http://" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="twitter icon" />
                    </a>
                </Button>
            </div> */}
        </div>
    )
}

export default Footer