import Title from "components/Title"
import { Col, Row, Spinner } from "reactstrap"
import defualt from "../asset/images/defualt.svg"
import { useParams } from "react-router-dom"
import { useDetails } from "hooks/news/details"
import { useEffect } from "react"

const MoreNews = () => {
    const { id } = useParams()
    const { data } = useDetails(id)
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    console.log('data', data)
    return (<div className="tech_bg">
        <Title width={75} name='At the Export Stars Awards Ceremony, our company won the first prize in the plastic kitchenware sector, as it was last year' />
        {data ? <div className="text-secondary px-5">
            <Row className="py-5">
                <Col md="6">
                    <img className="img-fluid corner-right w-100 h-100 object-fill-none" src={data?.news?.main_photo} alt="header" />
                </Col>
                <Col md="6" className="d-flex py-5 flex-column justify-content-evenly">
                    <h2 className="py-5 ">{data.news.title}</h2>
                    <p className="fs-3 pb-4 w-md-100">{data.news.description}</p>
                    <p className="fs-3 pb-4 w-md-100">{data.news.note}</p>
                </Col>
                {data?.news?.gallary.map((img, index) => {
                    return (<Col className="mt-5" xs="6" md='2' key={index}>
                        <img className="image-fluid corner-right w-100" src={img} onError={e => e.target.src = defualt} alt="" />
                    </Col>)
                })}
            </Row>
        </div> : <Spinner />}
    </div >)
}
export default MoreNews 