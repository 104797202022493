import Title from "components/Title"
import { Button, Col, Row, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useNews } from "hooks/news/news"
import defualt from "../asset/images/defualt.svg"
import { useTranslation } from "react-i18next"
import { useEffect } from "react"

const News = () => {
    const { data } = useNews()
    const { t } = useTranslation("index");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (<div className="tech_bg">
        <Title width={75} name='At the Export Stars Awards Ceremony, our company won the first prize in the plastic kitchenware sector, as it was last year' />
        <div className="text-secondary px-5">

            {data ? data.news.map((item, index) => {
                if (index % 2 === 0) {
                    // even item
                    return (
                        <Row className="pb-5 column" key={item.id}>
                            <Col md="6" className="d-flex py-5 flex-column pb-5">
                                <h2 className="pb-2 fw-bold">{item.title}</h2>
                                <p className="fs-3 pb-4 w-md-100">{item.description}</p>
                                <Link to={`/news/more/${item.id}`}>
                                    <Button className="w-md-50 w-75 p-1 p-md-4 fs-3 border-bill">{t("News Details")}</Button>
                                </Link>
                            </Col>
                            <Col md="6">
                                <img
                                    onError={(e) => (e.target.src = defualt)}
                                    className="img-fluid w-100 object-fill-none"
                                    src={item.main_photo}
                                    alt={item.title}
                                />
                            </Col>
                        </Row>
                    );
                } else {
                    // odd item
                    return (
                        <Row className=" pb-5  ">
                            <Col md="6">
                                <img
                                    onError={(e) => (e.target.src = defualt)}
                                    className="img-fluid corner-Square-right w-100 object-fill-none"
                                    src={item.main_photo}
                                    alt={item.title}
                                />
                            </Col>
                            <Col md="6" className="d-flex py-5 flex-column  pb-5">
                                <h2 className="pb-2 fw-bold">{item.title}</h2>
                                <p className="fs-3 pb-4 w-md-100">{item.description}</p>
                                <Link to={`/news/more/${item.id}`}>
                                    <Button className="w-md-50 w-75 p-1 p-md-4 fs-3 border-bill">{t('News Details')}</Button>
                                </Link>
                            </Col>
                        </Row>
                    );
                }
            }) : <Spinner color="primary" />}

        </div>
    </div>)
}
export default News 