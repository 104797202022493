import ProductCard from "../components/ProductCard"
import React, { useEffect } from "react"
import { Button, Col, Container, Row, Spinner } from "reactstrap"

import { useShowProduct } from "hooks/Product/useShowProduct"
import { Link, useParams } from 'react-router-dom'
import Gallery from "components/Gallery"
import { useTranslation } from "react-i18next"

const PreviewsProducts = () => {
    const { id } = useParams();
    const { data } = useShowProduct(id)
    const product = data ? data.product : ''
    const { t } = useTranslation("index");
    const Sizes = product ? product.sizes : null
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        data ?
            <div>
                <div style={{
                    background: 'linear-gradient(180deg, #DDDBD9 0%, #BDAFA5 99.99%, #AA9587 100%)',
                    boxShadow: '0px 1px 28px 0px rgba(217, 217, 217, 0.51)'
                }}>
                    <Container>
                        <div className="border-bottom border-secondary mb-5 pt-5 pb-2">
                            <h1 className="text-white">{product.name}</h1>
                        </div>
                        <Row>
                            <Col md="6" className="h-100 ">
                                <Gallery images={product.sizes} />
                            </Col>
                            <Col>
                                <div className="border-bottom border-secondary text-secondary d-flex flex-column justify-content-evenly" >
                                    <h2>{product.description}</h2>
                                    <ul >
                                        <li className="my-5 fs-5">
                                            {t('Code')} :{product.code}
                                        </li>
                                        {/* <li className="my-5 fs-5">
                                            {t('Box')} : {product.box}
                                        </li> */}
                                        <li className="my-5 fs-5">
                                            {t('Box Volume')}:{product.box_volume}
                                        </li>
                                        {/* <li className="my-5 fs-5">
                                            {t('Barcode')}:{product.barcode}
                                        </li> */}
                                        <li className="my-5 fs-5">
                                            {t('Sizes')} : {product.sizes?.map(item => <span className="mx-2" key={item.id}>{item.size}, </span>)}
                                        </li>
                                    </ul>
                                </div>
                                <Row className="d-flex p-5">
                                    <Col md="2" className="fs-4 h-100 d-flex align-items-center">{t('Colors')}</Col>
                                    <Col md="10" className="colors px-4 d-flex flex-wrap">
                                        {product.colors?.map(color => {
                                            return (<div key={color.id} className="circle " style={{ background: color.name }}></div>)
                                        })}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="tech_bg">
                    <h1 className="text-center pt-5 mb-5">
                        {t('Other Products You May Like')}
                    </h1>
                    <Row className="pb-5">
                        {Sizes?.map(item => {
                            return <Col md='4'>
                                <ProductCard size={item.size} code={item.code} img={item.image} />
                            </Col>
                        })}
                        <div className="w-100 text-center my-5"><Link to="/Products">
                            <Button className="border-bill  p-4 my-5  mx-auto my-py-2" color="secondary">{t('Show All Products')}</Button>
                        </Link>
                        </div>
                    </Row>
                </div>
            </div> : <div className="tech_bg text-center p-5">
                <Spinner color="primary" className="m-5" />
            </div>
    )
}
export default PreviewsProducts
