import React, { useEffect, useState } from "react";
import './App.scss';
import Home from "./pages/Home"
import 'bootstrap/dist/css/bootstrap.min.css';

// * api 
import { useSnackbar } from "notistack";
import { HttpResponseInterceptor } from "./interceptor/http-response.interceptor";
import { HttpRequestInterceptor } from "./interceptor/http-request.interceptor";
import { Routes, Route, Outlet, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
// pages
import Layout from "./layout/Layout";
import Corporate from "pages/Corporate";
import Dealership from "pages/Dealership";
import Product from "pages/Product";
import Products from "pages/Products";
import Videos from "pages/Videos";
import News from "pages/News";
import Contact from "pages/Contact";
import PreviewsProducts from "pages/PreviewsProducts";
import MoreNews from "pages/MoreNews";

function App() {
  <Helmet>
    <title>jewllery</title>
    <meta name="description" content="jewllery" />
  </Helmet>

  // const [lang, setLang] = useState("en");
  // const [direction, setdirection] = useState();

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // HttpRequestInterceptor();
    HttpResponseInterceptor(navigate, enqueueSnackbar);
  }, []);

  // useEffect(() => {
  //   const lang = localStorage?.getItem("i18nextLng");
  //   setLang(lang === "en" ? "en" : "ar");
  //   setdirection(lang === "en" ? "ltr" : "rtl");
  // }, [lang]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App" >
      <Routes>
        <Route
          element={
            <Layout>
              <Outlet />
            </Layout>
          }
        >
          <Route path="/" element={<Home />} />
          <Route path="/coraporate" element={<Corporate />} />
          <Route path="/Dealership" element={<Dealership />} />
          <Route path="/Contact" element={<Contact />} />
          <Route
            path="/Product/:id"
            element={<Product id={useParams().id} />}
          />
          <Route
            path="Products/Previews/:id"
            element={<PreviewsProducts id={useParams().id} />}
          />
          <Route path="/Products" element={<Products />} />
          <Route path="/Videos" element={<Videos />} />
          <Route path="/News" element={<News />} />

          <Route
            path="/News/more/:id"
            element={<MoreNews id={useParams().id} />}
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
