import { useQuery } from "react-query";
import { _all_product } from "api/Product/all_product";

export const useAllProduct = () => {
    const { data, isLoading } = useQuery(
        ["product"],
        () => _all_product.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};
