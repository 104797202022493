import { useDealership } from "hooks/dealership/dealership";
import React, { useEffect } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import defualt from "../asset/images/defualt.svg"
import rainbow from "../asset/images/rainbowPlast.png"
import icon from "../asset/images/dealershipIcon.svg"

const Dealership = () => {
    const { data } = useDealership()
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Container fluid className="defualt_bg ">
            {data ?
                <Row className="mt-5">
                    {data.dealership.map((item, index) => {
                        return (
                            <Col key={index} lg="3" md="6" className="text-center mt-5">
                                <img src={icon} alt={item.name} className="w-50" onError={(e) => (e.target.src = defualt)} />
                                <div className="text-white fs-3 fw-bold">{item.name}</div>
                                <div className="text-white fs-5">{item.address}</div>
                                <div className="text-white fs-5">{item.phone}</div>
                            </Col>
                        )
                    })}
                </Row> : <div className="text-center"><Spinner color="secondary" /></div>}
            <div className="text-center mt-5 pt-5">
                <img className="w-50 mx-auto mt-5" src={rainbow} alt="rainbow plast" />
            </div>
        </Container>
    )
}

export default Dealership