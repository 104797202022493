import React from "react";
import { Card } from "reactstrap";
import defualt from "../asset/images/defualt.svg"
import { useTranslation } from "react-i18next";

const ProductCard = ({ img, name, size, code, index }) => {
    const { t } = useTranslation("index");
    return (<Card className="bg-transparent bordeRoNmOBILE  my-2 p-3 text-center">
        <img
            onError={e => e.target.src = defualt}
            src={img}
            alt={`${name}`}
            className={`productImg mx-auto ${index % 2 === 0 ? "corner-Square-left" : "corner-Square-right"}`}
        />
        <div className="text-white fs-3 my-2">
            {name}
        </div>
        <div className="text-white fs-4" >{t('size')}: {size}</div>
        <div className="text-white fs-4">{t('code')}: {code}</div>
    </Card>)

}

export default ProductCard