import React, { useEffect, useState } from 'react';
import {
    GlassMagnifier,
    SideBySideMagnifier,
} from "react-image-magnifiers";

import { Col, Row } from 'reactstrap';
import defualt from "../asset/images/defualt.svg"

const Gallery = (images) => {

    const [selectedImage, setSelectedImage] = useState()

    useEffect(() => {
        if (images.images && images.images.length > 0) {
            setSelectedImage(images.images[0].image);
        }
    }, [images])

    return (
        <Row>
            <Col xs="3">
                {images.images?.map(item => {
                    return (
                        <div className="d-flex" key={item.id}>
                            <img
                                className='w-100 pointer mt-2'
                                onClick={() => setSelectedImage(item.image)}
                                src={item.image}
                                key={item.id}
                                alt={item.id}
                                onError={e => e.target.src = defualt}
                            />
                        </div>
                    )
                })}
            </Col>
            <Col xs="8">
                <GlassMagnifier
                    key={selectedImage}
                    imageSrc={selectedImage}
                    largeImageSrc={selectedImage}
                    alwaysInPlace={false}
                />
            </Col>
        </Row>
    );
}
export default Gallery