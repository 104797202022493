import React, { useEffect } from "react";
// *helmet
import { useTranslation } from "react-i18next";

// *reacrtstrap
import { Button } from "reactstrap"

//* images 
import image1 from "../asset/images/home/image (1).png"
import image2 from "../asset/images/home/image (2).png"
import image3 from "../asset/images/home/image (3).png"
import image4 from "../asset/images/home/image (4).png"
import image5 from "../asset/images/home/image (5).png"
import HomeGrid from "components/HomeGrid";
// * custom 
import Slider from "../components/Slider";
import { Link } from "react-router-dom";

function Home() {
  const { t } = useTranslation("index");
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="defualt_bg bg-tik">
      <Slider />
      <section>
        <div className="corner-left my-2 my-md-4 homeImg mx-4">
          <img className="vh-50 w-100 corner" src={image1} alt=" " />
        </div>
        <div className="corner-right my-2 my-md-4 homeImg mx-4"><img className="vh-50 w-100 corner" src={image2} alt=" " /></div>
        <div className="corner-left  my-2 my-md-4 homeImg mx-4"><img className="vh-50 w-100 corner" src={image3} alt=" " /></div>
        <div className="corner-left my-2 my-md-4 homeImg mx-4"><img className="vh-50 w-100 corner" src={image5} alt=" " /></div>
        <div className="corner-right my-2 my-md-4 homeImg mx-4"><img className="vh-50 w-100 corner" src={image4} alt=" " /></div>
        <div className="bg_blueTeck">
          <Link to="/Products" className=" d-flex jusify-content-center">
            <Button className="bg-secondary fw-bold mx-auto my-5 py-3 my-py-2 w-33 border-bill">
              {t("See Our Products")}
            </Button>
          </Link>
          <div className="fs-1 mt-5 mb-3 text-white pt-4">{t('New Items')}</div>
        </div>
      </section>
      <HomeGrid />
      {/* todo finish this  */}
      <footer className="text-center pb-5 text-white">
        <h1 className="mb-4">
          {t('CONTACT')}
        </h1>
        <h3>{t("Factory Address")}</h3>
        <p className="mb-4">{t('Lorem ipsum dolor sit amet consectetur. Sit at duis lorem nullam ')}</p>
        <h2>{t('Telephone Number')}</h2>
        <p className="mb-4">098765456789</p>
        <h1>{t('E-Mail')}</h1>
        <p>rainbow@info.com</p>
      </footer>
    </div>
  );
}

export default Home;
