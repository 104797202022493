import { _categories } from "api/categories/categories";
import { useQuery } from "react-query";

export const useCategory = () => {
    const { data, isLoading } = useQuery(
        ["category"],
        () => _categories.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};
