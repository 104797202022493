import React from "react";
import { Col, Row, Spinner } from "reactstrap";

import defualt from "../asset/images/defualt.svg"
import { useProduct } from "hooks/Product/useProduct";
import { Link } from "react-router-dom";

const HomeGrid = () => {
    const { data, isLoading } = useProduct()
    return (
        <div className="w-100 px-3 mb-5 " dir="ltr">
            <Row>
                {isLoading ? <Spinner color="primary" /> : ''}
                {data ? data.products.map((product, index) => {
                    if (index === 0) {
                        return (
                            <Col lg="6" sm='12' key={product.id} className="position-relative h-100 p-1">
                                <Link to={`product/${product.id}`}>
                                    <img
                                        onError={e => e.target.src = defualt}
                                        className="w-100 corner-Square-right p-md-0 p-2 gridImage"
                                        src={product.image}
                                        alt={product.name} />
                                    <div className="bg-secondary disc d-flex flex-column p-1 w-50 mx-auto alighn-items-center justify-content-center">
                                        <p className=" mb-0 fs-5 text-white text-center">{product.name}</p>
                                    </div>
                                </Link>
                            </Col>
                        );
                    }
                }) : ''}
                <Col lg="6" sm='12'>
                    <Row>
                        {data ? data.products.map((product, index) => {
                            if (index !== 0) {
                                return (
                                    <Col key={product.id} md='6' className="position-relative p-md-1 p-0 mt-2 mt-md-0">
                                        <Link to={`product/${product.id}`}>
                                            <img
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    e.target.src = defualt;
                                                }}
                                                className={`w-100 gridImage ${index % 2 == 0 ? "corner-Square-left" : "corner-Square-right"}`}
                                                src={product.image}
                                                alt={product.name} />
                                            <div className="bg-secondary disc d-flex flex-column p-1 w-50 mx-auto alighn-items-center justify-content-center">
                                                <p className=" mb-0  text-white text-center">{product.name}</p>
                                            </div>
                                        </Link>
                                    </Col>
                                );
                            }
                        }) : ''}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}
export default HomeGrid