import { _dealership } from "api/dealerShip/dealership";
import { useQuery } from "react-query";

export const useDealership = () => {
    const { data, isLoading } = useQuery(
        ["dealership"],
        () => _dealership.index().then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};
