import React from "react"
import { Col, Container, Row, Spinner } from "reactstrap"
import { useVideos } from "hooks/videos/useVideos"
import defualt from "../asset/images/defualt.svg"
import Title from "components/Title"
import { useTranslation } from "react-i18next"

const Videos = () => {
    const { data } = useVideos()
    const video = data ? data.video : ''
    const { t } = useTranslation("index");
    return (
        <div className="tech_bg">
            <Title name={t("Videos")} />
            <Container fluid className="py-5 border-top border-secondary">
                {data ? (
                    <Row>
                        {video?.map((item, index) => {
                            const isOdd = index % 2 === 1;
                            const cornerClass = isOdd ? "corner-left" : "corner-right";
                            return (
                                <Col md="6" className="px-md-3">
                                    <a className="w-100  d-inline-block" key={item.id} href={item.link} target="_blank" rel="noopener noreferrer">
                                        <img
                                            className={`mt-3  w-100 vh-40 ${cornerClass}`}
                                            onError={(e) => (e.target.src = defualt)}
                                            src={item.video_thump}
                                            alt=""
                                        />
                                    </a>
                                </Col>
                            );
                        })}
                    </Row>
                ) : <div className="text-center pt-5"><Spinner color="primary" /></div>}
            </Container>
        </div >
    )
}

export default Videos