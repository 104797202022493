// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import defualt from "../asset/images/defualt.svg"

import { useBanner } from 'hooks/Home/useBanner';
import { Spinner } from 'reactstrap';

const Slider = () => {
    const { data, isLoading } = useBanner()

    return (

        <Swiper
            spaceBetween={5}
            slidesPerView={1}
            autoplay={true}
            pagination={true}
            modules={[Pagination]}
            loop
            effect="fade"
            dir="rtl"
        >
            {data ? data.banner?.map(item => {
                return (<SwiperSlide key={item.id}>
                    <img
                        onError={e => e.target.src = defualt}
                        src={item.image}
                        className='corner-left'
                        style={{ objectFit: 'contain' }}
                        alt="rainbow"
                    />
                </SwiperSlide>)
            }) : <div className='h-100 d-flex'><Spinner
                color="primary"
                type="grow"
            >
            </Spinner></div>}
        </Swiper>
    );
};
export default Slider