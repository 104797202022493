import { useQuery } from "react-query";
import { _details } from "api/news/details";

export const useDetails = (id) => {
    const { data, isLoading } = useQuery(
        [`_details`],
        () => _details.index(id).then((res) => res),
        {
            keepPreviousData: true,
        }
    );
    return {
        data,
        isLoading,
    };
};