import React from "react";
import { useTranslation } from "react-i18next";

const Title = ({ name, width }) => {
    const { t } = useTranslation("index");
    return (
        <h2
            className={`py-3 py-md-5 px-1 px-md-5 fs-5 fs-md-6 text-secondary fw-bold my-3 d-flex align-items-center justify-content-center VideoTitle`}
        >
            <span className={`w-${width}`}>
                {t(name)}
            </span>
        </h2>
    )
}

export default Title