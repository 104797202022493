import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";

//* swiper 
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import { FreeMode } from 'swiper/modules';
// images
import arrow from "../asset/images/icon/Arrow.svg"
import aboutImage from "../asset/images/about.png"
import { useTranslation } from "react-i18next";
const Corporate = () => {
    const { t } = useTranslation("index");
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <Row className="tech_bg text-secondary p-4" >
            <Col md='6' className="p-2">
                <img
                    src={aboutImage}
                    alt="about us rainbow"
                    className="w-100"
                />
            </Col>
            <Col className="p-2" md='6'>
                <h3 className="mb-4" >{t('About Us')}</h3>
                <p className="mb-4 fs-5" >{t('Demirel Plastics and Mould Industry was established in 1990 as a company that would produce moulds for the plastic industry. The company, over the years, began to manufacture plastic houseware with ‘‘Rainbow’’ brand.')}</p>
                <p className="mb-4 fs-5" >{t('Having moved to its modern installations possessed of 40.000 m of indoor area located on Istanbul Hadimkoy in 2014, Demirel Plastics succeeded')}</p>
                <p className="mb-4 fs-5" >{t('Having moved to its modern installations possessed of 40.000 m of indoor area located on Istanbul Hadimkoy in 2014, Demirel Plastics succeeded')}</p>
                <p className="mb-4 fs-5" >{t('Tto become a company recognized in the international platform in such a short time as 10 years thanks to its qualified manufac turing and systematical working.')}</p>
                <p className="mb-4 fs-5" >{t('Demirel Plastics and Mould Industry was established in 1990 as a company that would produce moulds for the plastic industry. The company, over the years, began to manufacture plastic houseware with ‘‘Rainbow’’ brand.')}</p>

            </Col>
            <Col md='12' className="mt-5 text-white">
                <div className=" ms-md-5 ">
                    <h2>{t('MILESTONES')}</h2>
                    <h4 className="mb-5">{t('From past to present Rainbow')}</h4>
                </div>
                <div className="position-relative">
                    <Container>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={0}
                            freeMode={true}
                            modules={[FreeMode]}
                            dir="rtl"
                            className="time-slider"
                        >
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2011</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2012</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2013</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2014</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2015</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2016</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2017</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2018</p>
                                </div></SwiperSlide>
                            <SwiperSlide className="timeSlider">
                                <div className="d-flex justify-content-center alighn-items-center flex-column">
                                    <div className="line"></div>
                                    <p>2019</p>
                                </div></SwiperSlide>
                        </Swiper>

                    </Container>
                    <div className="number d-md-flex d-none flex-column justify-content-center align-items-center">
                        <p className="fs-3">2018---2023</p>
                        <img src={arrow} alt="" />
                    </div>
                </div>
            </Col>

        </Row>
    )
}

export default Corporate